import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "../utils/helper";
import {
  setPrifix,
  setPrivilagesData,
  setType,
  setMasterId,
} from "../store/slices/privilagesSlice";
import { jwtDecode } from "jwt-decode";
import { LOGIN_API } from "../utils/constant";
import { setIsAuthenticated, setToken } from "../store/slices/userSlice";
import useApiServices from "../utils/useApiServices";
import Cookies from "js-cookie";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const schema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  userName: Yup.string().required("Username is required"),
});
export default function Login() {
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  const { apiCallFnforPostForOpenUrl } = useApiServices();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsUserSignedIn(!!token);
  }, []);

  const dispatch = useDispatch();

  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));

  const AppNameCapitalized = Name;
  // const AppName = AppNameCapitalized.toUpperCase();
  useEffect(() => {
    if (!isUserSignedIn) {
      navigate("/");
    }
  }, [navigate, isUserSignedIn]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userName || !password) {
      setIsFormSubmitted(true);
      return;
    }

    try {
      await schema.validate({ password, userName }, { abortEarly: false });
      const response = await axios.post(LOGIN_API, { password, userName });

      console.log(response.data.statusCode);
      if (response?.data?.statusCode === 300) {
        const token = response?.data?.token;
        const privilage = response?.data?.data;
        const { prefix, userType, masterId } = jwtDecode(token);
        const privilages = privilage?.map(
          (item) => item.adminPrivilegeSubTypeId
        );

        dispatch(setPrivilagesData(privilages || []));
        dispatch(setPrifix(prefix));
        dispatch(setType(userType));
        dispatch(setMasterId(masterId));
        localStorage.setItem("token", token);
        dispatch(setToken(token));
        Cookies.set("access_token", token);

        dispatch(setIsAuthenticated(true));
        navigate("/Dashboard");
      } else {
        setErrorMessages([response.data.status]);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrorMessages(validationErrors);
      } else {
        setError(error.message);
      }
    }
  };
  if (showPopUp) {
    swal({
      title: "ALERT!",
      text: "Your Application Is Under Process!!",
      icon: "warning",
      timer: 2000,
    });
    setShowPopUp(!showPopUp);
  }

  return (
    <>
      <div className="authincation h-100">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-lg-6 col-md-12 col-sm-12 mx-auto align-self-center">
              <div className="login-form">
                <div className="text-center">
                  <h3 className="title">Log In</h3>
                  <p>Welcome back Please login to your account.</p>
                  <p className="text-red">Build is deployed</p>
                </div>
                {isFormSubmitted && (!userName || !password) && (
                  <div className="error-message alert alert-danger mb-4">
                    Please enter username and password
                  </div>
                )}
                {error && (
                  <p className="alert alert-danger mb-4">
                    {error ? "Something Went Wrong" : ""}
                  </p>
                )}
                {errorMessages?.map((errorMessage, index) => (
                  <p key={index} className="errorMsg alert alert-danger mb-4">
                    {errorMessage}
                  </p>
                ))}
                <form onSubmit={handleSubmit} className="login-right">
                  <div className="mb-4">
                    <label className="mb-1 text-dark">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={userName}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => setuserName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 form-group">
                    <label className="mb-1 text-dark">Password</label>
                    <div className="form-outline position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control pr-5"
                        placeholder="Password"
                        value={password}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {/* Eye icon */}
                      {showPassword ? (
                        <VisibilityOffIcon
                          className="position-absolute top-50 end-0 translate-middle-y"
                          style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            fontSize: "1.5em",
                          }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <VisibilityIcon
                          className="position-absolute top-50 end-0 translate-middle-y"
                          style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            fontSize: "1.5em",
                          }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}

                      {/* <i
            className={`bi ${showPassword ? "bi-eye-fill" : "bi-eye-slash-fill"} position-absolute top-50 end-0 translate-middle-y`}
            style={{ cursor: "pointer",  marginRight: "10px" , fontSize: "1.5em" }}
            onClick={() => setShowPassword(!showPassword)}
        ></i> */}
                    </div>
                    {errorMessages.password && (
                      <div className="alert alert-danger mb-4">
                        {errorMessages.password}
                      </div>
                    )}
                  </div>

                  <div className="form-row d-flex justify-content-end">
                    <div className="mb-4 ">
                      <Link
                        to="/ForgotPassword"
                        className="btn-link text-primary"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="text-center mb-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Log-In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="pages-left h-100">
                <div className="login-content">
                  <img
                    src={logoPath}
                    className="mt-3"
                    style={{
                      width: "auto",
                      maxHeight: "80px",
                      objectFit: "contain",
                    }}
                    alt="logo"
                  />

                  <p className="fs-3">Welcome to {Name}</p>
                </div>
                <div className="login-media text-center">
                  <img src="images/login.png" alt="png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

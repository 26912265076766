import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { GET_ALL_YES_ONBOARD } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import ViewDetailsMobileApp from "./ViewDetailsMobileApp";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import RouteIcon from "@mui/icons-material/Route";
import OnbordingApproved from "./OnbordingApproved";
import PreviewIcon from "@mui/icons-material/Preview";

const SubmittedRoutingPayin = ({ toggleRightBar }) => {
  const [applications, setApplications] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataApplication, setDataApplication] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState(null);
  const [showMobileApp, setShowMobileApp] = useState(false);
  const [mid, setMid] = useState("");
  const [openView,setOpenView] = useState(false);
  const [showPaymentServices, setShowPaymentServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [id, setId] = useState("");
  const [viewMobileApp, setViewMobileApp] = useState(false);
  const [viewApproved, setviewApproved] = useState(false);
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();


  const columns = [
    { headerName: "Company Name", field: "companyName", minWidth: 200 },
    { headerName: "Authorized Person Name", field: "authorizedPersonName", minWidth: 200 },
    { headerName: "e-Mail ID", field: "email", minWidth: 250 },
    { headerName: "Mobile Number", field: "mobile", minWidth: 150 },
    { headerName: "Company PAN Number", field: "companyPanNumber", minWidth: 200 },
    { headerName: "GST Number", field: "gstNumber", minWidth: 200 },
    { headerName: "Action", field: "action", minWidth: 150 },
  ];

  const handleSelect = (registrationId) => {
    setShowPaymentServices((prevShowPaymentServices) => ({
      ...prevShowPaymentServices,
      [registrationId]: !prevShowPaymentServices[registrationId],
    }));
  };


  const FetchData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_YES_ONBOARD);

      if (response.statusCode === 200) {
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          mid: item.mid,
          companyName: item.companyName, 
          masterMerchant: item.masterMerchant,
          mccCode: item.mccCode,
          registrationType: item.registrationType,
          businessType: item.businessType,
          businessTypeName: item.businessTypeName,
          companyType: item.companyType,
          businessAs: item.businessAs,
          companyPanNumber: item.companyPanNumber,
          gstNumber: item.gstNumber,
          authorizedPersonName: item.authorizedPersonName,
          email: item.email,
          mobile: item.mobile,
          authorizedPersonPan: item.authorizedPersonPan,
          aadharNumber: item.aadharNumber,
          websiteUrl: item.websiteUrl,
          aapUrl: item.aapUrl,
          companyAddress: item.companyAddress,
          companyAddressPinCode: item.companyAddressPinCode,
          companyAddressCity: item.companyAddressCity,
          companyAddressState: item.companyAddressState,
          registeredOfficeAddress: item.registeredOfficeAddress,
          registeredOfficeAddressPinCode: item.registeredOfficeAddressPinCode,
          registeredOfficeAddressCity: item.registeredOfficeAddressCity,
          registeredOfficeAddressState: item.registeredOfficeAddressState,
          communicationAddress: item.communicationAddress,
          communicationAddressPinCode: item.communicationAddressPinCode,
          communicationAddressCity: item.communicationAddressCity,
          communicationAddressState: item.communicationAddressState,
          modeOfRegistration: item.modeOfRegistration,
          modificationDate: item.modificationDate,
          submitDate: item.submitDate,
          referredBy: item.referredBy,
          paymentServices: item.paymentService.map(service => ({
            serviceName: service.paymentService,
            mdrPercentage: service.mdrPercentage,
          })),
          action: (
            <button
              className="badge badge-rounded badge-warning py-2 me-2"
              onClick={() => handleViewDetails(item)}
            >
              View Details
            </button>
          ),
         
        }));
        setApplications(finalRes);
      } else {
        setApplications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setviewApproved(false);
  };


  const handleViewDetails = (data) => {
    const registrationId = data?.registrationId;
    setDataApplication(data); 
    setId(registrationId);
    setViewMobileApp(true);
  };

  const handleApproved = () => setviewApproved(!viewApproved);

  useEffect(() => {
    FetchData();
  }, [refresh]);

  return (
    <>
      <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center me-3">
          <div className="d-flex align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

          { !viewApproved ?
          <div className="fs-2 text-dark fw-bold">Mobile App On-Bording Submitted</div>
          : <div className="fs-2 text-dark fw-bold">Mobile App On-Bording Approved</div>
          }

          </div>

          <div className="d-flex align-items-center mt-3">
            <CustomTip size="18" placement="top" 
            title={ !viewApproved ? "Approved" : "Submitted" }
            >
              <div className="me-4 " 
              onClick={handleApproved}
              >
                <PreviewIcon fontSize="large" />
              </div>
            </CustomTip>
           
          </div>
        </div>
        { !viewApproved ?
        <div>
          <DataGrid
            rows={applications}
            columns={columns.map((column) => ({
              ...column,
              renderCell: (params) =>
                column.field === "active" || column.field === "action"
                  ? params.value
                  : params.value,
            }))}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </div> 
        : 
        <OnbordingApproved />
} 
      </div>

      {viewMobileApp && (
      <ViewDetailsMobileApp
        id={id}
        data={dataApplication}
        showMobileApp={viewMobileApp}
        onHide={() => setViewMobileApp(false)}  // Handle closing
        setRefresh={setRefresh}
        refresh={refresh}
      />
)}
          
    </>
  );
};

export default SubmittedRoutingPayin;

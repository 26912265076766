import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../../utils/constant";
import { Container, Row, Col, Card, Offcanvas } from "react-bootstrap";
// import "../stylesheet/ViewDetails.css";
// import "../assets/css/viewFile1.css";
import DocumentList from "./DocumentList";
import VideosList from "./VideosList";

const MobileAppApprovedDetails = ({ id, data, handleClose , openView}) => {
  console.log(data);
  const [merchantData, setMerchantData] = useState(data);
  const [paymentService, setPaymentServioces] = useState(data?.paymentService);
  const [showLoader, setShowLoader] = useState(Boolean);
  const token = localStorage.getItem("token");
  const offcanvasStyle = {
    width: "90vw", 
  };


  return (
    <>
      <Offcanvas
      show={openView}
      onHide={handleClose}
      placement="end"
      style={offcanvasStyle}
      backdrop="static"
      keyboard={false}
    >
     
        <Offcanvas.Title class="d-flex">
        <Offcanvas.Header class="d-flex align-items-center" closeButton></Offcanvas.Header>
          <div class="fs-2 text-dark fw-bold">Mobile App On-Boarding</div>
        </Offcanvas.Title>
      
      <Offcanvas.Body>
      <div className="two-column-layout">
      <table className="table table-bordered border-dark">
  <tbody>
    <tr>
      <td className="fw-bold">Company Name</td>
      <td>{merchantData?.companyName}</td>
      <td className="fw-bold">Merchant Master</td>
      <td>{merchantData?.masterMerchant}</td>
    </tr>
    <tr>
      <td className="fw-bold">MCC Code</td>
      <td>{merchantData?.mccCode}</td>
      <td className="fw-bold">Business Category</td>
      <td>{merchantData?.businessType}</td>
    </tr>
    <tr>
      <td className="fw-bold">Other Business Category</td>
      <td>{merchantData?.businessTypeName}</td>
      <td className="fw-bold">Business Type</td>
      <td>{merchantData?.companyType}</td>
    </tr>
    <tr>
      <td className="fw-bold">Business As</td>
      <td>{merchantData?.businessAs}</td>
      <td className="fw-bold">Company PAN Number</td>
      <td>{merchantData?.companyPanNumber}</td>
    </tr>
    <tr>
      <td className="fw-bold">GST Number</td>
      <td>{merchantData?.gstNumber}</td>
      <td className="fw-bold">Authorized Person Name</td>
      <td>{merchantData?.authorizedPersonName}</td>
    </tr>
    <tr>
      <td className="fw-bold">e-Mail ID</td>
      <td>{merchantData?.email}</td>
      <td className="fw-bold">Mobile Number</td>
      <td>{merchantData?.mobile}</td>
    </tr>
    <tr>
      <td className="fw-bold">Authorized Person PAN</td>
      <td>{merchantData?.authorizedPersonPan}</td>
      <td className="fw-bold">Aadhaar Number</td>
      <td>{merchantData?.aadharNumber}</td>
    </tr>
    <tr>
      <td className="fw-bold">Website URL</td>
      <td>{merchantData?.websiteUrl}</td>
      <td className="fw-bold">Application URL</td>
      <td>{merchantData?.aapUrl}</td>
    </tr>
    <tr>
      <td className="fw-bold">Company Registered Address</td>
      <td>{merchantData?.companyAddress}</td>
      <td className="fw-bold">Pin Code</td>
      <td>{merchantData?.companyAddressPinCode}</td>
    </tr>
    <tr>
      <td className="fw-bold">City</td>
      <td>{merchantData?.companyAddressCity}</td>
      <td className="fw-bold">State</td>
      <td>{merchantData?.companyAddressState}</td>
    </tr>
    <tr>
      <td className="fw-bold">Authorized Signatory Address</td>
      <td>{merchantData?.registeredOfficeAddress}</td>
      <td className="fw-bold">Pin Code</td>
      <td>{merchantData?.registeredOfficeAddressPinCode}</td>
    </tr>
    <tr>
      <td className="fw-bold">City</td>
      <td>{merchantData?.registeredOfficeAddressCity}</td>
      <td className="fw-bold">State</td>
      <td>{merchantData?.registeredOfficeAddressState}</td>
    </tr>
    <tr>
      <td className="fw-bold">Company Communication Address</td>
      <td>{merchantData?.communicationAddress}</td>
      <td className="fw-bold">Pin Code</td>
      <td>{merchantData?.communicationAddressPinCode}</td>
    </tr>
    <tr>
      <td className="fw-bold">City</td>
      <td>{merchantData?.communicationAddressCity}</td>
      <td className="fw-bold">State</td>
      <td>{merchantData?.communicationAddressState}</td>
    </tr>
    <tr>
      <td className="fw-bold">Mode of Registration</td>
      <td>{merchantData?.modeOfRegistration}</td>
      <td className="fw-bold">Modification Date</td>
      <td>{merchantData?.modificationDate}</td>
    </tr>
    <tr>
      <td className="fw-bold">Submission Date</td>
      <td>{merchantData?.submitDate}</td>
      <td className="fw-bold">Submission Time</td>
      <td>{merchantData?.submitTime}</td>
    </tr>
    <tr>
      <td className="fw-bold">Referred By</td>
      <td>{merchantData?.referredBy}</td>
      <td className="fw-bold">Turnover Type</td>
      <td>{merchantData?.turnoverType}</td> {/* Added from the first table */}
    </tr>
    <tr>
      <td className="fw-bold">Primary Director Name</td>
      <td>{merchantData?.primaryDirectorName}</td> {/* Added from the first table */}
      <td className="fw-bold">Account Number</td>
      <td>{merchantData?.merchantAccountNumber}</td> {/* Added from the first table */}
    </tr>
    <tr>
      <td className="fw-bold">Bank Name</td>
      <td>{merchantData?.merchantBankName}</td> {/* Added from the first table */}
      <td className="fw-bold">Branch Name</td>
      <td>{merchantData?.bankBranchName}</td> {/* Added from the first table */}
    </tr>
    <tr>
      <td className="fw-bold">IFSC Code</td>
      <td>{merchantData?.merchantIfscCode}</td> {/* Added from the first table */}
      <td className="fw-bold">Latitude</td>
      <td>{merchantData?.latitute}</td> {/* Added from the first table */}
    </tr>
    <tr>
      <td className="fw-bold">Longitude</td>
      <td>{merchantData?.longitute}</td> {/* Added from the first table */}
      <td className="fw-bold">Client Type</td>
      <td>{merchantData?.clientType}</td> {/* Added from the first table */}
    </tr>
  </tbody>
</table>


  {/* <div className="d-flex justify-content-end">
    <button type="button" className="btn btn-primary" onClick={handleClose}>
      Close
    </button>
  </div> */}
</div>


    <DocumentList id={merchantData.registerId}  />
    <VideosList id={merchantData.registerId} />
        
      </Offcanvas.Body>
    </Offcanvas>
    </>
  );
};

export default MobileAppApprovedDetails;
